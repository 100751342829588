import { SHIFTS, IShift } from '../providers/bookAssistant/shifts'

export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }, wait)
    if (immediate && !timeout) func.apply(context, args)
  }
}

export const humanizeShift = (shift: Partial<IShift>) => {
  if (!shift) {
    return 'Unkown'
  }

  const match = Object.values(SHIFTS).find(({ from, to }) => shift.from === from && shift.to === to)
  if (!match) {
    return 'Unkown'
  }

  return match.shortLabel
}

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

export const isSameDay = (a, b) => {
  return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year')
}

export const IS_SAFARI = () => navigator.userAgent.indexOf('Safari') > -1
