import React from 'react'
import styles from './SettingsScreen.module.css'
import Screen from '../../../../components/molecules/screen/Screen'
import BackButton from '../../../../components/atoms/backButton/BackButton'
import Text from '../../../../components/atoms/text/Text'
import { VARIANTS } from '../../../../types/Components.types'
import SectionHeader from '../components/sectionHeader/SectionHeader'
import IconProfile from '../../../../components/icons/IconProfile'
import LinkRow from '../../../../components/molecules/linkRow/LinkRow'
import routes from '../../../../config/routes'
import IconNotifications from '../../../../components/icons/IconNotifications'
import IconInfo from '../../../../components/icons/IconInfo'
import Link from '../../../../components/atoms/link/Link'
import { useIsGUCSL } from '../../../../modules/auth/hooks'
import { ordenanza, prices, terms } from '../../../../config'
import { logout } from '../../../../modules/auth'

const SettingsScreen = () => {
  const { isGUCSL } = useIsGUCSL()

  return (
    <Screen hasDecorator decoratorSize="small">
      <div className={styles.container}>
        <div className={styles.header}>
          <BackButton className={styles.backButton} />
          <Text size="l" variant={VARIANTS.primary} weight="700">
            Ajustes
          </Text>
        </div>

        <div className={styles.content}>
          <>
            <SectionHeader icon={<IconProfile size="s" variant="max" />}>Mi cuenta</SectionHeader>

            <LinkRow path={routes.password.forgot}>Cambiar contraseña</LinkRow>

            <LinkRow path={routes.settings.account.privacy}>Privacidad</LinkRow>

            <LinkRow path={routes.settings.account.vouchers.home}>Mis bonos</LinkRow>

            <LinkRow path={routes.settings.account.invoices.home}>Mis facturas</LinkRow>

            <LinkRow path={routes.settings.account.billingAddress}>Dirección de facturación</LinkRow>

            {isGUCSL && <LinkRow path={routes.settings.account.gucsl.member}>Mis ventajas del GUCSL</LinkRow>}

            <SectionHeader icon={<IconNotifications size="s" variant="max" />} className={styles.block}>
              Documentación
            </SectionHeader>

            <LinkRow path={routes.settings.account.attachments.update}>Domicilio fiscal</LinkRow>

            <LinkRow path={routes.settings.account.attachments.dni}>Documento de identidad</LinkRow>

            <SectionHeader icon={<IconInfo size="s" variant="max" />} className={styles.block}>
              El coworking
            </SectionHeader>

            <LinkRow path={routes.gdpr}>Política de privacidad</LinkRow>

            <LinkRow path={routes.cookies}>Política de cookies</LinkRow>

            <LinkRow href={prices} rel="noreferrer" target="_blank">
              Precios públicos
            </LinkRow>

            <LinkRow href={ordenanza} rel="noreferrer" target="_blank">
              Ordenanza del Coworking
            </LinkRow>

            <LinkRow href={terms} rel="noreferrer" target="_blank">
              Términos de uso
            </LinkRow>

            <div className={styles.logoutWrapper}>
              <Link onClick={logout} path={routes.welcome} replace>
                <Text weight="500" size="ml">
                  Cerrar sesión
                </Text>
              </Link>
            </div>
          </>
        </div>
      </div>
    </Screen>
  )
}

export default React.memo(SettingsScreen)
