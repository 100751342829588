import React from 'react'
import styles from './ListItem.module.css'
import c from 'classnames'
import Text from '../../atoms/text/Text'

interface IProps {
  className?: string
  label: string
  children: React.ReactNode
}

const ListItem = ({ className, label, children }: IProps) => {
  return (
    <div className={c(styles.container, className)}>
      <Text>{label}</Text>
      {children}
    </div>
  )
}

export default React.memo(ListItem)
