const routes = {
  landing: '/',
  baseApp: '/app',
  app: '/app',
  gdpr: '/app/gdpr',
  cookies: '/app/cookies',
  geolocation: '/app/geolocation',
  notifications: '/app/notifications',
  gucsl: '/app/gucsl',
  welcome: '/app/welcome',
  home: '/app/home',
  registerWelcome: '/app/register/welcome',
  register: '/app/register',
  login: '/app/login',
  payments: {
    ok: '/app/ok/:book/:bank/:paymentId',
    ko: '/app/ko/:book/:bank/:paymentId',
    renew: {
      ok: '/app/renew/ok/:bank/:paymentId',
      ko: '/app/renew/ko/:bank/:paymentId',
    },
  },
  validation: {
    validate: '/app/validation',
    emailPending: '/app/validation/email',
    coworkingPending: '/app/validation/coworking',
  },
  password: {
    forgot: '/app/forgot-password',
    emailSent: '/app/forgot-password/email-sent',
    recover: '/app/recover-password',
    complete: '/app/recover-password/done',
  },
  onboarding: {
    home: '/app/onboarding',
    profile: '/app/onboarding/profile',
    creation: '/app/onboarding/creation',
    complete: '/app/onboarding/complete',
  },
  profile: {
    details: '/app/profile',
    edit: '/app/profile/edit',
  },
  book: {
    new: {
      seat: {
        renewWarning: '/app/book/renew',
        details: '/app/book/seat',
        place: '/app/book/seat/place',
        confirmation: '/app/book/seat/confirmation',
        success: '/app/book/seat/success',
      },
      place: {
        details: '/app/books/place',
        success: '/app/books/place/success',
      },
      meetingsRoom: {
        details: '/app/books/:bookId/meetingsRoom',
        success: '/app/books/:bookId/meetingsRoom/success',
      },
    },
    list: '/app/book/list',
    details: '/app/book/details/:bookId',
    meetingRoomDetails: '/app/meetingroom/details/:reservationId',
    changeSeat: '/app/book/details/:bookId/change/seat',
    changeMeetingRoom: '/app/book/meetingroom/details/:reservationId/change/slot',
    workmates: '/app/book/details/:bookId/workmates',
    workmate: '/app/book/details/:bookId/workmates/:profileId',
    vouchers: '/app/book/buy/voucher',
    payment: '/app/book/buy/voucher/payment/:voucherId',
    cancel: '/app/book/cancel/:bookId',
  },
  settings: {
    home: '/app/settings',
    account: {
      privacy: '/app/settings/account/privacy',
      billingAddress: '/app/settings/account/billing',
      list: {
        vouchers: '/app/settings/account/vouchers',
        invoices: '/app/settings/account/invoices',
      },
      vouchers: {
        home: '/app/settings/account/vouchers',
        byId: '/app/settings/account/vouchers/:voucherId',
        invoices: '/app/settings/account/vouchers/:voucherId/invoices',
      },
      invoices: {
        home: '/app/settings/account/invoices',
        byYear: '/app/settings/account/invoices/:year',
      },
      gucsl: {
        member: '/app/settings/account/gucsl',
        cancel: '/app/settings/account/gucsl/cancel',
      },
      attachments: {
        dni: '/app/settings/account/dni',
        update: '/app/settings/account/padron',
      },
    },
  },
}

export const generateRoute = (route: string, params: { name: string; value: string }[]) => {
  let generatedRoute = route
  params.forEach(({ name, value }) => {
    generatedRoute = generatedRoute.replace(`:${name}`, value)
  })
  return generatedRoute
}

export default routes
