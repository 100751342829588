import { useFormik } from 'formik'
import { DateTime } from 'luxon'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/atoms/button/Button'
import Loader from '../../../../components/atoms/loader/Loader'
import Text from '../../../../components/atoms/text/Text'
import RadioCard from '../../../../components/molecules/radioCard/RadioCard'
import RadioGroup from '../../../../components/molecules/radioGroup/RadioGroup'
import Screen from '../../../../components/molecules/screen/Screen'
import routes, { generateRoute } from '../../../../config/routes'
import { useProfile } from '../../../../modules/auth/hooks'
import { useReservatedSeatById, useSeatById, useSeatsQueryParams } from '../../../../modules/seats/hooks'
import { usePurchasedVoucher, usePurchaseVoucherMutation, useVouchers } from '../../../../modules/vouchers/hooks'
import { getVoucherPrice } from '../../../../modules/vouchers/utils'
import { useBookAssistant } from '../../../../providers/bookAssistant'
import { SHIFTS } from '../../../../providers/bookAssistant/shifts'
import { TYPES } from '../../../../providers/bookAssistant/types'
import { IVoucher } from '../../../../types/app.types'
import { VARIANTS } from '../../../../types/Components.types'
import { useQuery } from '../../../../utils/hooks/useQuery'
import styles from './ReservationVouchersScreen.module.css'
import voucherFormSchema from './schema/voucher'

const ReservationVouchersScreen = () => {
  const history = useHistory()
  const query = useQuery()
  const reservationParams = JSON.parse(atob(query.get('b') ?? '{}'))
  const { type, day, shift, seat: seatId, months } = useBookAssistant()
  const seatsQueryParams = useSeatsQueryParams()
  const { data: seat, isLoading: isLoadingSeats } = useSeatById({ seatId, queryParams: seatsQueryParams })

  const { data: profile, isLoading: isLoadingProfile } = useProfile()
  const { data: reservation, isLoading: isLoadingReservation } = useReservatedSeatById({
    userId: profile?.id,
    seatId: reservationParams.bookId,
  })
  const { data: voucher, isLoading: isLoadingVoucher } = usePurchasedVoucher({
    userId: profile?.id,
    voucherId: reservation?.seat.userVoucher,
  })
  const queryParams = React.useMemo(() => {
    const lookingFor = type !== TYPES.MEETING_ROOM ? 'seat' : 'meetingroom'
    const params: {
      type: string
      date: string
      recursive: string
      seatType?: string
      from?: string
      to?: string
      bookId?: string
    } = {
      type: lookingFor,
      date: DateTime.fromJSDate(day!).toISODate(),
      recursive: String(type === TYPES.MONTH),
    }

    if (type !== TYPES.MEETING_ROOM) {
      params.seatType = seat?.type
      params.from = String(shift.from ?? SHIFTS.morning.from)
      params.to = String(shift.to ?? SHIFTS.morning.to)
    } else if (type === TYPES.MEETING_ROOM) {
      params.bookId = reservationParams.bookId
    }

    return new URLSearchParams(params)
  }, [day, reservationParams.bookId, seat?.type, shift, type])

  const { data: vouchers, isLoading: isLoadingVouchers } = useVouchers({
    queryParams,
  })

  const isLoading =
    isLoadingProfile || isLoadingReservation || isLoadingVoucher || isLoadingVouchers || (seatId && isLoadingSeats)
  const purchaseVoucher = usePurchaseVoucherMutation()

  const { handleSubmit, values, handleBlur, touched, errors, isValid, isSubmitting, setFieldValue } = useFormik({
    initialValues: {
      voucherId: '',
    },
    validateOnMount: true,
    validationSchema: voucherFormSchema,
    onSubmit: async (values) => {
      return new Promise<void>((resolve, reject) => {
        purchaseVoucher.mutate(
          {
            ...values,
            userId: profile!.id,
            seatType: (seat?.type ?? 'red') as 'red' | 'yellow' | 'blue',
            months,
            params: query.get('b'),
            userSeatId: reservationParams?.bookId,
          },
          {
            onSuccess: ({ status, voucher }) => {
              resolve()
              if (status === 202) {
                switch (type) {
                  case TYPES.MONTH:
                  case TYPES.DAY:
                    history.replace(routes.book.new.seat.success, { goBack: routes.app })
                    return

                  default:
                    history.replace(
                      generateRoute(routes.book.new.meetingsRoom.details, [
                        { name: 'bookId', value: reservationParams?.bookId },
                      ]),
                    )
                }
                return
              }
              history.push(generateRoute(routes.book.payment, [{ name: 'voucherId', value: voucher.id }]))
            },
            onError: (error) => {
              reject()
              console.info(error)
            },
          },
        )
      })
    },
  })

  const renderVoucher = useCallback(
    (props: any) => {
      const isPadron = profile?.padron ?? false
      const isEmpresa = profile?.dtoEmpresa ?? false
      const isFreeUse = profile?.dtoFreeUse ?? false
      let seatType = 'red'
      if (seat) {
        seatType = seat.type
      } else {
        seatType = voucher?.spec?.seatType ?? 'red'
      }
      return (
        <RadioCard
          {...props}
          selectedValue={values.voucherId}
          price={getVoucherPrice({ voucher: props.option, isPadron, isEmpresa, isFreeUse, seatType })}
        />
      )
    },
    [profile?.padron, profile?.dtoEmpresa, profile?.dtoFreeUse, seat, values.voucherId, voucher?.spec?.seatType],
  )

  const getExtraInfo = useCallback((voucher: IVoucher) => {
    const { spec } = voucher
    const { meetingroom } = spec
    if (!meetingroom || meetingroom.hours === 0) {
      return null
    }
    return `Incluye ${meetingroom.hours}h para la sala de reuniones`
  }, [])

  return (
    <Screen
      headerProps={{
        backEnabled: true,
        variant: VARIANTS.cocodrile,
        title: 'Comprar bono',
      }}
    >
      {!isLoading && (
        <form className={styles.container} onSubmit={handleSubmit}>
          <Text size="l" variant={VARIANTS.primary} weight="700" className={styles.title}>
            ¡Oops!
          </Text>

          <Text>Tienes que adquirir un bono de días para poder acceder al coworking</Text>

          <div className={styles.vouchers}>
            <RadioGroup
              name="voucherId"
              value={values?.voucherId}
              options={(vouchers ?? []).map((voucher) => {
                return {
                  label: voucher.name,
                  value: voucher.id,
                  extraInfo: getExtraInfo(voucher),
                  ...voucher,
                }
              })}
              onChange={(name, value) => {
                setFieldValue(name, value)
              }}
              onBlur={handleBlur}
              renderItem={renderVoucher}
              touched={!!touched?.voucherId}
              error={errors?.voucherId}
            />
          </div>

          <div className={styles.actions}>
            <Button
              variant={VARIANTS.primary}
              className={styles.action}
              onClick={handleSubmit}
              type="submit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              Comprar bono
            </Button>
          </div>
        </form>
      )}

      {isLoading && (
        <div className={styles.loadingWrapper}>
          <Loader variant="primary" />
        </div>
      )}
    </Screen>
  )
}

export default React.memo(ReservationVouchersScreen)
