import { DateTime } from 'luxon'
import { IUserPurchaseVoucher, IVoucher } from '../../types/app.types'

const DAYS_BEFORE_EXPIRATION = 15

export const isRenewable = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  if (!voucher) {
    return false
  }

  const { lastSeat } = voucher

  if (!lastSeat) {
    return false
  }

  const diffInDaysToEndOfVoucher = DateTime.fromISO(lastSeat?.reserved_from).diffNow('days').days
  // Indefined -1
  if (voucher.autoRenew && voucher.maxMonthsToRenew < 0) {
    return diffInDaysToEndOfVoucher <= DAYS_BEFORE_EXPIRATION
  }

  const maxMonthsToRenew = voucher.maxMonthsToRenew - 1
  if (!voucher.autoRenew || voucher.monthsRenewed >= maxMonthsToRenew) {
    return false
  }

  return diffInDaysToEndOfVoucher > 0 && diffInDaysToEndOfVoucher <= DAYS_BEFORE_EXPIRATION
}

export const getBookMonths = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  return (voucher?.maxMonthsToRenew ?? 1) - 1
}

export const getBookRenewedMonths = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  return voucher?.monthsRenewed ?? 0
}

export const getBookAutoRenew = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  return voucher?.autoRenew
}

export const getHumanizedBookMonths = ({ voucher }: { voucher?: IUserPurchaseVoucher }) => {
  return voucher?.maxMonthsToRenew ?? 0
}

export const round = (number: number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

export const getVoucherPrice = ({
  voucher,
  seatType,
  isPadron,
  isEmpresa,
  isFreeUse,
}: {
  voucher?: IVoucher
  seatType?: string
  isPadron: boolean
  isEmpresa: boolean
  isFreeUse: boolean
}) => {
  if (!voucher) {
    return { cost: 0, discount: 0, percent: 0 }
  }

  const { price } = voucher
  let type: 'red' | 'yellow' | 'blue' = 'red'
  if (seatType) {
    type = seatType as 'red' | 'yellow' | 'blue'
  } else if (voucher) {
    type = voucher?.spec?.seatType || 'red'
  }

  const { discounts } = price
  const {
    dtoEmpresa = { red: 0, yellow: 0, blue: 0 },
    dtoFreeUse = { red: 0, yellow: 0, blue: 0 },
    padron = { red: 0, yellow: 0, blue: 0 },
  } = discounts
  let discountApplied = 0
  let discountPercent = 0
  // Adding taxes
  const priceApplied = price[type]
  const priceWithTaxes = priceApplied * 1.21
  if (isPadron) {
    discountApplied = padron[type] * priceApplied
    discountPercent = padron[type] * 100
  } else if (isEmpresa) {
    discountApplied = dtoEmpresa[type] * priceApplied
    discountPercent = dtoEmpresa[type] * 100
  } else if (isFreeUse) {
    discountApplied = dtoFreeUse[type] * priceApplied
    discountPercent = dtoFreeUse[type] * 100
  }

  discountApplied *= 1.21

  return {
    cost: round(priceWithTaxes),
    discount: round(discountApplied),
    percent: discountPercent,
    total: round(priceWithTaxes - discountApplied),
    withDiscountApplied: round(priceWithTaxes - discountApplied),
  }
}
